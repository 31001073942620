import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import Box from '@mui/material/Box';
import SettingsIcon from '@mui/icons-material/Settings';
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  height: '.5rem',
  '@media (min-width:600px)': {
    height: '.5rem',
  }
});

theme.typography.h3 = {
fontSize: '.5rem',
'@media (min-width:600px)': {
  fontSize: '.5rem',
},
[theme.breakpoints.up('md')]: {
  fontSize: '1.5rem',
},
};

const Camera=({onClick, name, status, cameraID, setShow})=>{

  const [photoCover, setPhotoCover] = useState();

  useEffect(() => {
    fetch('/photos/latest_photo/' + cameraID)
      .then(res => res.json())
      .then(data => {
        setPhotoCover(data);
      })
      .catch(err => console.log(err));
  }, []);

  const onClickSettings = () => {
    setShow(true);
  }

    return(
          <Box sx={{ margin:'1px', border: 'solid', borderWidth: '0.6px', borderColor: status ? 'green' : 'red', padding: '2px'}}>
            <Card sx={{ width:'100%'}}>
              <CardActionArea>
                <CardMedia
                  onClick={onClick}
                  component="img"
                  src={`data:image/jpeg;base64,${photoCover?.thumbnail_data}`}
                  alt="camera"
                />
              </CardActionArea>
              <ThemeProvider theme={theme}>
              <CardActions sx={{backgroundColor: 'red', justifyContent: 'space-between', padding:'3px'}} >
                <Typography variant="h3" color="common.white">{name}</Typography>
              </CardActions>
              </ThemeProvider>
            </Card>
          </Box>
    )
}


export default Camera;
